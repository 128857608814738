import { useContext, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import functions from "../../functions/functions";
import PlusMinusLarge from "../ui/PlusMinusLarge";

function SplitPopup({ options }) {
	const { config, menu, openOrder, setSplitPopup, setLoading, setConfirm } =
		useContext(AppContext);
	const [tempCart, setTempCart] = useState({ ...options.order, romana: 2 });
	const [mode, setMode] = useState("products");

	const handleSave = () => {
		if (mode == "romana" || !hasCoversUnassigned()) save();
		else
			setConfirm({
				title: "Attenzione!",
				message: "Non sono stati assegnati coperti al nuovo ordine\nProseguire comunque?",
				onConfirm: () => { },
			});
	};

	const hasCoversUnassigned = () => {
		let hasCoversInMain = false;
		let hasCoversInCopy = false;
		for (const product of tempCart.products) {
			if (product.is_cover == 1 && product.qty > 0) hasCoversInMain = true;
			if (product.is_cover == 1 && product.movedQty > 0) hasCoversInCopy = true;
		}
		return hasCoversInMain && !hasCoversInCopy;
	};

	const save = async () => {
		console.log("save");
		setLoading(true);
		const res = await api.postProtected("/orders/split/" + getId() + "/", {
			mode: mode,
			romana: tempCart.romana,
			products: formatProducts(tempCart.products),
		});
		setLoading(false);
		if (res.success == 0) alert(res.error);
		else {
			setSplitPopup(false);
			if (options.mode == "cart" && res.order) openOrder(res.order);
		}
	};

	const getId = () => {
		let id = tempCart.id;
		if (options.mode == "cart") id = tempCart.order_id;
		if (config.mode == "local") id = tempCart["$loki"];
		return id;
	};

	const formatProducts = () => {
		if (options.mode == "list") return tempCart.products;
		else {
			let res = [];
			for (const p of tempCart.products) {
				res.push({ ...p, id: p.item_id });
			}
			return res;
		}
	};

	const countCol2 = () => {
		let tot = 0;
		for (const product of tempCart.products) {
			if (product.movedQty) tot += parseInt(product.movedQty);
		}
		return tot;
	};

	return (
		<PopUp
			size=""
			options={{
				onClose: () => setSplitPopup(false),
				title: "Dividi Ordine " + getId(),
			}}
		>
			<div className="inner-content">
				<div className="horizzontal-menu">
					<button
						onClick={() => setMode("products")}
						className={mode == "products" ? "active" : ""}
					>
						Per prodotti
					</button>
					<button
						onClick={() => setMode("romana")}
						className={mode == "romana" ? "active" : ""}
					>
						Alla romana
					</button>
				</div>
				{mode == "products" && (
					<ProductsMode tempCart={tempCart} setTempCart={setTempCart} />
				)}
				{mode == "romana" && <RomanaMode tempCart={tempCart} setTempCart={setTempCart} />}
			</div>
			<div className="popup-footer">
				{((mode == "products" && countCol2() > 0) || mode == "romana") && (
					<button className="button button-2" onClick={handleSave}>
						Dividi
					</button>
				)}
			</div>
		</PopUp>
	);
}

function ProductsMode({ tempCart, setTempCart }) {
	const addToSelection = (i) => {
		console.log("addToSelection");
		if (countCol1() == 1) return;
		let nCart = { ...tempCart };
		nCart.products[i].qty--;
		if (nCart.products[i].movedQty) nCart.products[i].movedQty++;
		else nCart.products[i].movedQty = 1;
		setTempCart(nCart);
	};

	const removeFromSelection = (i) => {
		console.log("removeFromSelection");
		let nCart = { ...tempCart };
		nCart.products[i].qty++;
		if (nCart.products[i].movedQty) nCart.products[i].movedQty--;
		else delete nCart.products[i].movedQty;
		setTempCart(nCart);
	};

	const getTotalCol1 = () => {
		let tot = 0;
		for (const product of tempCart.products) {
			tot += parseInt(product.qty) * parseFloat(product.price);
		}
		return functions.formatter.format(tot);
	};

	const getTotalCol2 = () => {
		let tot = 0;
		for (const product of tempCart.products) {
			if (product.movedQty) tot += parseInt(product.movedQty) * parseFloat(product.price);
		}
		return functions.formatter.format(tot);
	};

	const countCol1 = () => {
		let tot = 0;
		for (const product of tempCart.products) {
			tot += parseInt(product.qty);
		}
		return tot;
	};

	return (
		<>
			<div className="split-box">
				<div>
					{tempCart.products.map(
						(product, i) =>
							product.qty > 0 && (
								<button key={i} onClick={() => addToSelection(i)}>
									{product.qty}x {product.name}
								</button>
							)
					)}
				</div>
				<div>
					{tempCart.products.map(
						(product, i) =>
							product.movedQty > 0 && (
								<button key={i} onClick={() => removeFromSelection(i)}>
									{product.movedQty}x {product.name}
								</button>
							)
					)}
				</div>
			</div>
			<div className="split-box totals">
				<div>{getTotalCol1()}</div>
				<div>{getTotalCol2()}</div>
			</div>
		</>
	);
}

function RomanaMode({ tempCart, setTempCart }) {
	const { apiSettings } = useContext(AppContext);

	const getQuote = (i) => {
		let num = parseFloat(tempCart.total) / tempCart.romana;

		num = roundNumberBySettings(num);

		if (i == tempCart.romana - 1) {
			num = tempCart.total - getSumNotLast();
			num = roundNumber(num);
		}

		return num;
	};

	const getSum = () => {
		let sum = 0;
		for (let i = 0; i < tempCart.romana; i++) {
			sum += getQuote(i);
		}
		sum = roundNumber(sum);
		return sum;
	};

	const getSumNotLast = () => {
		let sum = 0;
		for (let i = 0; i < tempCart.romana - 1; i++) {
			sum = sum + getQuote(i);
		}
		sum = roundNumber(sum);
		return sum;
	};

	const roundNumber = (number, decimals = 2) => {
		var newnumber = new Number(number + "").toFixed(parseInt(decimals));
		return parseFloat(newnumber);
	};

	const roundNumberBySettings = (number) => {
		let decimals = 2;
		if (apiSettings.pos.romana_round == 2) decimals = 1;
		if (apiSettings.pos.romana_round == 3) decimals = 0;
		var newnumber = new Number(number + "").toFixed(parseInt(decimals));
		return parseFloat(newnumber);
	};

	return (
		<>
			<PlusMinusLarge
				value={tempCart.romana}
				onChange={(value) => setTempCart({ ...tempCart, romana: value })}
				min={2}
			/>
			<div className="quotes">
				{[...Array(tempCart.romana)].map((el, i) => (
					<div key={i}>
						<div className="quotes-header">Quota {i + 1}</div>
						<div className="quotes-content">
							{functions.formatter.format(getQuote(i))}
						</div>
					</div>
				))}
			</div>

			<div className="quotes-total">
				Totale: {functions.formatter.format(getSum())}{" "}
				<div className="small">
					({getSumNotLast()}-{tempCart.total})
				</div>
			</div>
		</>
	);
}

export default SplitPopup;
