import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import functions from "../../functions/functions";
import DotIcon from "../../icons/DotIcon";
import BarIcon from "../../icons/BarIcon";
import LoadIcon from "../../icons/LoadIcon";

function PosPaymentPopup({ order }) {
	const { setPosPaymentPopup, setLoading, setConfirm, init_cart } = useContext(AppContext);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!error) {
			const timeout = checkOrderTimer();
			return () => clearInterval(timeout);
		}
	}, [error]);

	const checkOrderTimer = async () => {
		const timeout = setInterval(async () => {
			const res = await api.postProtected("/orders/get/" + order.id + "/", {
				depth: 0,
				fields: ["id", "status"],
			});
			if (res.success == 1) {
				if (res.bill == 1) {
					clearInterval(timeout);
					init_cart();
					window.parent.postMessage("close", "*");
					setPosPaymentPopup(false);
				}
				if (res.data.status == "payment_error") {
					clearInterval(timeout);
					setError(res.data.status);
				}
			} else
				setConfirm({
					title: "Errore",
					message: res.error,
				});
		}, 5000);
		return timeout;
	};

	const cancel = async () => {
		setConfirm({
			title: "Attenzione",
			message: "Sei sicuro di annullare il pagamento?",
			onConfirm: async () => {
				setLoading(true);
				const res = await api.postProtected("/orders/edit/" + order.id + "/", {
					status: "archived",
				});
				setLoading(false);
				if (res.success == 1) {
					setPosPaymentPopup(false);
				} else
					setConfirm({
						title: "Errore",
						message: res.error,
					});
			},
		});
	};

	const retry = async () => {
		setLoading(true);
		const res = await api.postProtected("/orders/edit/" + order.id + "/", {
			status: "awaiting_payment",
		});
		setLoading(false);
		if (res.success == 1) {
			setError(false);
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	return (
		<>
			<PopUp
				customClass="electronic-payment"
				size=""
				options={{
					onClose: () => cancel(),
					title: "Pagamento Elettronico",
				}}
			>
				{!error ? (
					<div className="inner-content">
						<span><LoadIcon></LoadIcon></span>
						<h3>Richiesta di pagamento in corso</h3>
						<div className="total-import"><h3>Importo totale:</h3> <h1>{functions.formatter.format(order.total)}</h1></div>
						<h5>Verifica il dispositivo di pagamento per procedere</h5>
					</div>
				) : (
					<div className="inner-content error-retry">
						<h3>Errore</h3>
						<h1>{error}</h1>
						<button onClick={retry}>Riprova</button>
					</div>
				)}
			</PopUp>
		</>
	);
}

export default PosPaymentPopup;
