import { useContext } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import FormGroup from "../form/FormGroup";

function InvoiceFormPopup({ address, refreshAddress }) {
	const { cart, setInvoiceFormPopup, setLoading, setConfirm } = useContext(AppContext);

	const saveAddress = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		const request = Object.fromEntries(data.entries());
		let endpoint = "";
		console.log(address);
		if (address && address.id) {
			endpoint = "/addresses_billing/edit/" + address.id + "/";
		} else {
			endpoint = "/addresses_billing/add/";
			request.customer_id = cart.customer.id;
		}
		setLoading(true);
		const res = await api.postProtected(endpoint, request);
		setLoading(false);
		if (res.success == 1) {
			setInvoiceFormPopup(false);
			refreshAddress();
		} else {
			setConfirm({
				title: "Errore",
				message: res.error,
			});
		}
	};

	return (
		<PopUp
			size=""
			options={{
				onClose: () => setInvoiceFormPopup(false),
				title: "Fattura",
			}}
		>
			<div className="inner-content">
				<form onSubmit={saveAddress}>
					<div className="form">
						<FormGroup
							id="company"
							name="company"
							label="Ragione Sociale"
							value={address.id ? address.company : null}
							required={true}
						/>
						<FormGroup
							id="vat"
							name="vat"
							label="P.Iva"
							value={address.id ? address.vat : null}
							required={true}
						/>
						<FormGroup
							id="sdi"
							name="sdi"
							label="Sdi"
							value={address.id ? address.sdi : null}
						/>
						<FormGroup
							id="pec"
							name="pec"
							label="Pec"
							value={address.id ? address.pec : null}
						/>
						<FormGroup
							id="taxcode"
							name="taxcode"
							label="C.F."
							value={address.id ? address.taxcode : null}
						/>
						<FormGroup
							id="address"
							name="address"
							label="Indirizzo"
							value={address ? address.address : null}
							required={true}
						/>
						<FormGroup
							id="number"
							name="number"
							label="Civico"
							value={address.id ? address.number : null}
							required={true}
						/>
						<FormGroup
							id="zipcode"
							name="zipcode"
							label="Cap"
							value={address.id ? address.zipcode : null}
							required={true}
						/>
						<FormGroup
							id="city"
							name="city"
							label="Città"
							value={address.id ? address.city : null}
							required={true}
						/>
						<FormGroup
							id="province"
							name="province"
							label="Provincia"
							value={address.id ? address.province : null}
							required={true}
						/>
					</div>
					<button className="button2 success mt-2">Salva</button>
				</form>
			</div>
		</PopUp>
	);
}

export default InvoiceFormPopup;
