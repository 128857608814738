import { useContext, useEffect } from "react";
import PopUp from "./PopUp";
import { AppContext } from "../App";
import api from "./api/api";
import Table from "./ui/Table";

function SellsList() {
	const { config, setSellsListVisible, sells, setConfirm, loadSells } = useContext(AppContext);

	useEffect(() => {
		loadSells();
	}, []);

	const cancelOrder = async (order) => {
		console.log("cancelOrder");

		const res = await api.postProtected("/fiscal_printers/send_command/", {
			command: "CANCEL_ORDER",
			order: order,
		});
		if (res.success == 0)
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	return (
		<PopUp
			size="large"
			options={{
				onClose: () => setSellsListVisible(false),
				title: "Vendite",
				content: (
					<Table
						header={true}
						config={config}
						cols={[
							{ id: "id", label: "ID", type: "id", align: "center" },
							{
								id: "delivery_type",
								label: "Tipo",
								type: "delivery_type",
								align: "center",
							},
							{
								id: "delivery_date",
								label: "Ora",
								type: "delivery_date",
								align: "center",
							},
							{
								id: "origin",
								label: "Origine",
								type: "origin",
								align: "center",
							},
							{ id: "customer", label: "Cliente", type: "customer" },
							{ id: "info", label: "Info", type: "info" },
							{ id: "total", label: "Totale", type: "eur", align: "right" },
							{
								id: "buttons",
								label: "",
								type: "buttons",
								buttons: [
									{
										label: "Annulla Vendita",
										onClick: (order) => {
											setConfirm({
												title: "Sei sicuro?",
												onConfirm: () => {
													cancelOrder(order);
													setSellsListVisible(false);
												},
											});
										},
									},
								],
							},
						]}
						rows={sells}
					/>
				),
			}}
		/>
	);
}

export default SellsList;
