import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import FormGroup from "./form/FormGroup";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import PopUp2 from "./PopUp2";

function AddressForm() {
	const {
		cart,
		apiSettings,
		addressFormPopUpVisible,
		setAddressPopUpVisible,
		setAddressFormPopUpVisible,
	} = useContext(AppContext);
	const [googleAddress, setGoogleAddress] = useState(null);
	const [err, setErr] = useState("");

	useEffect(() => {
		if (typeof addressFormPopUpVisible === "object") {
			document.getElementById("address").value = addressFormPopUpVisible.address;
			document.getElementById("number").value = addressFormPopUpVisible.number;
			document.getElementById("zipcode").value = addressFormPopUpVisible.zipcode;
			document.getElementById("city").value = addressFormPopUpVisible.city;
			document.getElementById("province").value = addressFormPopUpVisible.province;
			document.getElementById("coord").value = addressFormPopUpVisible.coord;
			document.getElementById("doorphone").value = addressFormPopUpVisible.doorphone;
		}
	}, []);

	useEffect(() => {
		console.log(googleAddress);
		if (googleAddress) fillInAddress();
	}, [googleAddress]);

	function fillInAddress() {
		console.log("fillInAddress");
		geocodeByPlaceId(googleAddress.value.place_id)
			.then((place) => {
				console.log(place);
				const addressComponents = place[0].address_components;
				addressComponents.forEach(function (component) {
					if (component.types[0] == "route")
						document.getElementById("address").value = component.short_name;
					if (component.types[0] == "street_number")
						document.getElementById("number").value = component.short_name;
					if (component.types[0] == "postal_code")
						document.getElementById("zipcode").value = component.short_name;
					if (component.types[0] == "locality")
						document.getElementById("city").value = component.long_name;
					if (component.types[0] == "administrative_area_level_3")
						document.getElementById("city").value = component.long_name;
					if (component.types[0] == "administrative_area_level_2")
						document.getElementById("province").value = component.short_name;
				});
				document.getElementById("coord").value =
					place[0].geometry.location.lat() + "," + place[0].geometry.location.lng();
			})
			.catch((error) => console.error(error));
	}

	function check() {
		let e = "";
		if (!document.getElementById("address").value) e += "Indirizzo è obbligatorio\n";
		if (
			!document.getElementById("number").value &&
			apiSettings.pos?.optional_address_number != 1
		)
			e += "Numero civico è obbligatorio\n";
		if (!document.getElementById("zipcode").value) e += "Cap è obbligatorio\n";
		if (!document.getElementById("city").value) e += "Città è obbligatorio\n";
		if (!document.getElementById("province").value) e += "Provincia è obbligatorio\n";
		if (!document.getElementById("coord").value) e += "Errore Coordinate\n";
		if (
			apiSettings.customers?.doorphone_required == 1 &&
			!document.getElementById("doorphone").value
		)
			e += "Citofono è obbligatorio\n";
		setErr(e);
		return e == "";
	}

	const saveAddress = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let address = Object.fromEntries(data.entries());
		if (!check(address)) return;
		if (typeof addressFormPopUpVisible === "object") {
			const res = await api.postProtected(
				"/addresses_delivery/edit/" + addressFormPopUpVisible.id + "/",
				address
			);
		} else {
			address.customer_id = cart.customer.id;
			const res = await api.postProtected("/addresses_delivery/add/", address);
		}
		setAddressFormPopUpVisible(false);
		setAddressPopUpVisible(true);
	};

	return (
		<PopUp2
			title="Indirizzo"
			onClose={() => {
				setAddressFormPopUpVisible(false);
				setAddressPopUpVisible(true);
			}}
		>
			{err && <div className="error-box">{err}</div>}
			<div>Cerca:</div>
			<GooglePlacesAutocomplete
				apiKey={apiSettings.google.api_key}
				autocompletionRequest={{
					componentRestrictions: {
						country: ["it"],
					},
					types: ["address"],
				}}
				selectProps={{
					googleAddress,
					onChange: setGoogleAddress,
				}}
			/>
			<form onSubmit={saveAddress}>
				<div className="form">
					<FormGroup id="address" name="address" label="Indirizzo" />
					<FormGroup id="number" name="number" label="Civico" />
					<FormGroup id="zipcode" name="zipcode" label="Cap" />
					<FormGroup id="city" name="city" label="Città" />
					<FormGroup id="province" name="province" label="Provincia" />
					<FormGroup id="doorphone" name="doorphone" label="Citofono" />
				</div>

				<input type="hidden" id="coord" name="coord" />
				<button className="button2 success mt-2">Salva</button>
			</form>
		</PopUp2>
	);
}

export default AddressForm;
