import { useContext } from "react";
import { AppContext } from "../App";

function PopUp({ options, children, size = "", customClass = "" }) {
	const { setPopup } = useContext(AppContext);
	return (
		<div className={"popup " + size + "" + customClass}>
			<div className="content">
				<div className="title">
					<div className="title-popup">
						{options.title}
						{options.subtitle && (
							<div className="subtitle-popup">{options.subtitle}</div>
						)}
					</div>
					<div className="buttons-group">
						{options.headerButton && (
							<button onClick={options.headerButton.onClick}>
								{options.headerButton.label}
							</button>
						)}
						{options.headerButtons}
						<button
							className="close-button"
							onClick={() => {
								if (options.onClose) options.onClose();
								else setPopup(false);
							}}
						>
							&times;
						</button>
					</div>
				</div>
				{options.content && <div className="inner-content">{options.content}</div>}
				{options.fullContent && options.fullContent}
				{children && children}
				{options.footer && options.footer}
			</div>
		</div>
	);
}

export default PopUp;
