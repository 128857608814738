import DotIcon from "../icons/DotIcon";

function ButtonLabeled({
	label,
	value,
	onClick,
	valid = true,
	disabled = false,
	ellipsis = false,
	icon = false,
	vertical = false,
	selected = false,
}) {
	return (
		<button
			className={
				"button-labeled" +
				(disabled ? " disabled" : "") +
				(ellipsis ? " ellipsis" : "") +
				(vertical ? " vertical" : "") +
				(selected ? " selected" : "")
			}
			onClick={() => {
				if (!disabled) onClick();
			}}
		>
			{icon && <div className="icon">{icon}</div>}
			<div className="info">
				<div className="label">{label}</div>
				<div className="value">{value}</div>

				{!valid && (
					<div className="error">
						<div>Richiesto</div>
					</div>
				)}
			</div>
		</button>
	);
}

export default ButtonLabeled;
