import { useContext, useState } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import logo from "../images/logo.png";
import GearIcon from "../icons/GearIcon";
import LogoutIcon from "../icons/LogoutIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import DrawerIcon from "../icons/DrawerIcon";
import DotIcon from "../icons/DotIcon";

export default function Footer() {
	const {
		apiSettings,
		config,
		setSettingsVisible,
		setUser,
		setConfirm,
		setSellsListVisible,
		cloudStatus,
		setReportsPopUpVisible,
		shop,
	} = useContext(AppContext);
	const [menuOpen, setMenuOpen] = useState(false);

	function toggleSubmenu() {
		if (menuOpen) setMenuOpen(false);
		else setMenuOpen(true);
	}

	const sendCommand = async (type = "DAILY_FINANCIAL_REPORT") => {
		console.log("sendCommand");

		setConfirm({
			title: "Sei sicuro?",
			onConfirm: async () => {
				const res = await api.postProtected("/fiscal_printers/send_command/", {
					fiscal_printer_id: config.fiscal_printer_id,
					command: type,
				});
				if (res.success == 0)
					setConfirm({
						title: "Errore",
						message: res.error,
					});
			},
		});
	};

	const sendThermalPrintersCommand = async (command) => {
		console.log("sendThermalPrintersCommand", command);

		setConfirm({
			title: "Sei sicuro?",
			onConfirm: async () => {
				const res = await api.postProtected("/thermal_printers/send_command/", {
					shop_id: shop.id,
					command: command,
				});
				if (res.success == 0)
					setConfirm({
						title: "Errore",
						message: res.error,
					});
			},
		});
	};

	return (
		<div className="footer">
			<div className="logo-container">
				<img src={logo} className="logo" />
			</div>
			<div className={"menu-container" + (menuOpen ? " open" : "")}>
				{apiSettings.mandant.fiscal_printers == 0 && (
					<>
						<button
							onClick={() => {
								setMenuOpen(false);
								setReportsPopUpVisible(true);
							}}
						>
							Stampa report
						</button>
					</>
				)}
				{apiSettings.mandant.fiscal_printers == 1 && (
					<>
						<button
							onClick={() => {
								sendCommand("DUPLICATE");
								setMenuOpen(false);
							}}
						>
							Ristampa ultimo scontrino
						</button>
						<button
							onClick={() => {
								setSellsListVisible(true);
								setMenuOpen(false);
							}}
						>
							Vendite
						</button>
						<button
							onClick={() => {
								sendCommand("DAILY_FINANCIAL_REPORT");
								setMenuOpen(false);
							}}
						>
							Stampa report giornaliero
						</button>
						<button
							onClick={() => {
								sendCommand("DAILY_FISCAL_CLOUSE");
								setMenuOpen(false);
							}}
						>
							Stampa chiusura
						</button>
					</>
				)}
				<button
					onClick={() => {
						setMenuOpen(false);
						sendThermalPrintersCommand("check_unprinted");
					}}
				>
					Stampa ordini arretrati
				</button>
			</div>
			<div className="user-container">
				{config.mode == "local" && (
					<button className="icon" style={{ color: cloudStatus ? "green" : "red" }}>
						<DotIcon />
						Cloud
					</button>
				)}
				<button onClick={toggleSubmenu}>
					<HamburgerIcon />
				</button>
				{apiSettings.mandant.fiscal_printers == 1 && (
					<button
						onClick={() => {
							sendCommand("OPEN_DRAWER");
							setMenuOpen(false);
						}}
					>
						<DrawerIcon />
					</button>
				)}
				<button
					onClick={() => {
						setSettingsVisible(true);
						setMenuOpen(false);
					}}
				>
					<GearIcon />
				</button>

				<button
					onClick={() => {
						setUser(false);
						localStorage.removeItem("pos-user");
						setMenuOpen(false);
					}}
				>
					<LogoutIcon />
				</button>
			</div>
		</div>
	);
}
