import * as React from "react";

function PlusCircle(props) {
	return (
		<svg
		viewBox="0 0 24 24"
		fill="currentColor"
		height="1em"
		width="1em"
		{...props}
	  >
		<path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
	  </svg>
	);
}

export default PlusCircle;
