import { Fragment } from "react";
import moment from "moment/moment";
import DeliveryType from "../icons/DeliveryType";
import functions from "../../functions/functions";
import Origins from "../icons/Origins";

function Table({
	header = false,
	rows = [],
	cols = [],
	showProducts = false,
	repeatHeader = false,
	smallHeader = false,
	config,
}) {
	const checkVisibility = (visible, row) => {
		if (visible == undefined) return true;
		if (visible === true) return true;
		if (typeof visible === "function") return visible(row);
	};

	return (
		<div
			className="grid-table"
			style={{ gridTemplateColumns: "repeat(" + cols.length + ", auto)" }}
		>
			{header && !repeatHeader && <Header cols={cols} smallHeader={smallHeader} />}
			{rows.map((row, i) => (
				<Fragment key={i}>
					{header && repeatHeader && <Header cols={cols} smallHeader={smallHeader} />}
					{cols.map((col, h) => (
						<div key={h} className={col.align || ""}>
							{col.type == "text" && <>{row[col.id]}</>}
							{col.type == "id" && (
								<>
									<div>{"N." + row.number}</div>
									{config.mode == "local" && <div>{"INT:" + row["$loki"]}</div>}
									<div>{"ID:" + row.id}</div>
								</>
							)}
							{col.type == "delivery_type" && (
								<DeliveryType deliveryType={row.delivery_type} color="#000000" />
							)}
							{col.type == "delivery_date" && (
								<>
									<div>
										{row.delivery_date &&
											moment(row.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
												"HH:mm"
											)}
									</div>
									<div className="small">
										{row.delivery_date &&
											moment(row.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
												"DD/MM/YYYY"
											)}
									</div>
									<div className="small">{row.shop.name}</div>
								</>
							)}
							{col.type == "origin" && (
								<Origins origin={row.origin} color="#000000" />
							)}
							{col.type == "customer" && (
								<>
									<div className="small">
										{row.name && row.name} {row.surname && row.surname}
									</div>
									{row.mobile && (
										<div className="small">{"Tel: " + row.mobile}</div>
									)}
								</>
							)}
							{col.type == "user" && <>{row.user && row.user.name}</>}
							{col.type == "info" && (
								<>
									{row.table_id && (
										<>
											<div className="small">Sala: {row.room?.name}</div>
											<div className="small">Tavolo: {row.table.name}</div>
										</>
									)}
									<div className="small">
										Listino: {row.list_id ? row.list.name : "base"}
									</div>
									{row.delivery_type == "delivery" && (
										<>
											<div className="small">
												{row.delivery_address.address +
													" " +
													row.delivery_address.number}
											</div>
											<div className="small">
												{row.delivery_address.zipcode +
													" " +
													row.delivery_address.city}
											</div>
										</>
									)}
								</>
							)}
							{col.type == "eur" && (
								<div>{functions.formatter.format(row[col.id])}</div>
							)}
							{col.type == "bool" && (
								<>
									{row[col.id] == 1 ? (
										<div className="success">SI</div>
									) : (
										<div className="error">NO</div>
									)}
								</>
							)}
							{col.type == "buttons" && (
								<div style={{ display: "flex", gap: "5px" }}>
									{col.buttons.map(
										(button, n) =>
											checkVisibility(button.visible, row) && (
												<button
													key={n}
													onClick={() => button.onClick(row)}
													className={
														"button button-1" +
														(button.icon ? " square icon" : "")
													}
												>
													{button.label && button.label}
													{button.icon && button.icon}
												</button>
											)
									)}
								</div>
							)}
						</div>
					))}
					{showProducts &&
						row.products &&
						row.products.map((product, n) => (
							<div key={n} style={{ gridColumn: "span " + cols.length }}>
								<div className="horizzontal-group large">
									{product.qty}x {product.name}
									<div className="horizzontal-group">
										<div>{functions.formatter.format(product.price)}</div>
										<div>{functions.formatProductStatus(product.status)}</div>
									</div>
								</div>
							</div>
						))}
				</Fragment>
			))}
		</div>
	);
}

function Header({ cols, smallHeader }) {
	return (
		<>
			{cols.map((col, i) => (
				<div key={i} className={"grid-table-header" + (smallHeader ? " small" : "")}>
					{col.label}
				</div>
			))}
		</>
	);
}

export default Table;
