import { useContext } from "react";
import { AppContext } from "../../App";

function Flows({ flow, setFlow }) {
	const { activeFlow, setActiveFlow } = useContext(AppContext);
	const flows = [1, 2, 3, 4, 5];
	return (
		<div className="cart-flows-buttons">
			{flows.map((el, i) => (
				<button
					key={i}
					className={activeFlow == el ? "selected" : ""}
					onClick={() => setActiveFlow(el)}
				>
					{"Portata " + el}
				</button>
			))}
		</div>
	);
}

export default Flows;
