import { useContext, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import FormGroup from "../form/FormGroup";

function EditProductPopup() {
	const { cart, setCart, editingProduct, setEditingProduct } = useContext(AppContext);
	const [notes, setNotes] = useState(editingProduct.data.notes || "");
	const [flow, setFlow] = useState(editingProduct.data.flow || 1);
	const flows = [1, 2, 3, 4, 5];

	const save = () => {
		let nCart = { ...cart };
		nCart.products[editingProduct.i].flow = flow;
		nCart.products[editingProduct.i].notes = notes;
		setCart(nCart);
		setEditingProduct(false);
	};

	return (
		<PopUp
			size=""
			options={{
				onClose: () => setEditingProduct(false),
				title: "Modifica prodotto",
				subtitle: editingProduct.data.name,
			}}
		>
			<div className="inner-content">
				<div>Portata:</div>
				<div className="cart-flows-buttons">
					{flows.map((el, i) => (
						<button
							key={i}
							className={flow == el ? "selected" : ""}
							onClick={() => setFlow(el)}
						>
							{"Portata " + el}
						</button>
					))}
				</div>
				<div>Note prodotto:</div>
				<FormGroup
					type="textarea"
					value={notes}
					onChange={(e) => {
						setNotes(e.target.value);
					}}
				/>
			</div>
			<div className="popup-footer">
				<button className="button button-2" onClick={save}>
					Salva
				</button>
			</div>
		</PopUp>
	);
}

export default EditProductPopup;
