import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import api from "../api/api";
import List from "../List";
import functions from "../../functions/functions";
import moment from "moment/moment";
import FormGroup from "../form/FormGroup";
import Flows from "./Flows";
import TrashIcon from "../../icons/TrashIcon";
import PrecentageIcon from "../../icons/PrecentageIcon";
import Takeaway from "../../icons/Takeaway";
import Table from "../../icons/Table";
import Delivery from "../../icons/Delivery";
import PlusCircle from "../../icons/PlusCircle";
import PrinterIcon from "../../icons/PrinterIcon";
import SplitIcon from "../../icons/SplitIcon";

function Cart() {
	const {
		apiSettings,
		cart,
		setCart,
		editCart2,
		init_cart,
		setPopup,
		shop,
		setConfirm,
		activeFlow,
		setDiscountsPopup,
		setEditingProduct,
		edited,
		setEdited,
		config,
		setSplitPopup,
		rooms,
		checkout,
		openOrder,
		setInvoicePopup,
		updateOrderFromCart,
		total,
		setTotal,
		deliveryPrice,
		setDeliveryPrice,
		round,
		setRound,
	} = useContext(AppContext);
	const [valid, setValid] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (cart) {
			check();
			calcuclateTotal();
			localStorage.setItem("pos-delivery_type", JSON.stringify(cart.delivery_type));
		}
	}, [cart, round, apiSettings]);

	const check = () => {
		console.log("check");
		let error = false;
		let passed = true;

		if (!cart.shop_id) passed = false;
		if (cart.products.length == 0) {
			error = "Nessun prodotto";
			passed = false;
		}

		if (cart.delivery_type.id == "takeaway") {
			if (!cart.delivery_date) {
				error = "Manca la data di ritiro";
				passed = false;
			}
		}

		if (cart.delivery_type.id == "delivery") {
			if (
				!cart.customer &&
				cart.origin != "glovo" &&
				cart.origin != "deliveroo" &&
				cart.origin != "justeat"
			) {
				error = "Manca il cliente";
				passed = false;
			} else if (!cart.address) {
				error = "Manca l'indirizzo";
				passed = false;
			} else if (!cart.delivery_date) {
				error = "Manca la data di consegna";
				passed = false;
			}
		}

		if (apiSettings.pos.mandatory_customer == 1 && !cart.customer) {
			error = "Manca il cliente";
			passed = false;
		}

		setValid(passed);
		setError(error);
	};

	const calcuclateTotal = () => {
		//console.log("calcuclateTotal");
		let total = 0;
		for (const product of cart.products) {
			total += product.qty * product.price;
		}
		for (let index = 0; index < cart.discounts.length; index++) {
			if (
				cart.discounts[index].value_type == "perc" ||
				cart.discounts[index].value_type == "percent"
			) {
				const val = (total / 100) * cart.discounts[index].value;
				total -= val;
				cart.discounts[index].price = val;
			} else {
				total -= cart.discounts[index].value;
				cart.discounts[index].price = cart.discounts[index].value;
			}
		}
		console.log(
			"delivery_price",
			cart.delivery_type.id,
			cart.address?.delivery_free_min,
			cart.address?.delivery_price
		);
		let delivery = 0;
		if (cart.delivery_type.id == "delivery" && cart.address) {
			delivery = parseFloat(cart.address.delivery_price);
			if (
				cart.address.delivery_free_min &&
				total >= parseFloat(cart.address.delivery_free_min)
			)
				delivery = 0;
		}
		if (cart.credit_total) total -= parseFloat(cart.credit_total);
		total += parseFloat(delivery);

		const round = calculateRound(total);
		total += round;

		setDeliveryPrice(delivery);
		setRound(round);
		setTotal(total);
	};

	const calculateRound = (total) => {
		let round = 0;
		if (apiSettings.pos.order_round == 2) {
			const roundedTotal = roundNumber(total, 1, apiSettings.pos.order_round_mode);
			round = roundNumber(roundedTotal - total);
			console.log(total, roundedTotal, round);
		}
		if (apiSettings.pos.order_round == 3) {
			const roundedTotal = roundNumber(total, 0, apiSettings.pos.order_round_mode);
			round = roundNumber(roundedTotal - total);
			console.log(total, roundedTotal, round);
		}
		return round;
	};

	const roundNumber = (number, decimals = 2, mode = 1) => {
		let newnumber = number;
		console.log(newnumber);
		if (decimals == 1) newnumber = newnumber * 10;
		if (decimals == 2) newnumber = newnumber * 100;
		console.log(newnumber);
		if (mode == 1) newnumber = Math.round(newnumber);
		if (mode == 2) newnumber = Math.ceil(newnumber);
		if (mode == 3) newnumber = Math.floor(newnumber);
		console.log(newnumber);
		if (decimals == 1) newnumber = newnumber / 10;
		if (decimals == 2) newnumber = newnumber / 100;
		console.log(newnumber, mode);
		return parseFloat(newnumber);
	};

	const subQty = (i) => {
		let nCart = { ...cart };
		if (nCart.products[i].qty > 1) nCart.products[i].qty--;
		else nCart.products.splice(i, 1);
		setCart(nCart);
		setEdited(true);
	};

	const addQty = (i) => {
		let nCart = { ...cart };
		nCart.products[i].qty++;
		setCart(nCart);
		setEdited(true);
	};

	const removeDiscount = (i) => {
		let nCart = { ...cart };
		nCart.discounts.splice(i, 1);
		setCart(nCart);
	};

	const generateCovers = () => {
		let res = [];
		for (let i = 1; i <= 20; i++) {
			res.push({ id: i, name: i });
		}
		return res;
	};

	const cancelOrder = async () => {
		const id = config.mode == "local" ? cart["$loki"] : cart.order_id;
		await api.postProtected("/orders/edit/" + id + "/", {
			status: "archived",
		});
	};

	const isEditing = () => {
		//console.log("isEditing", cart);
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		//console.log("isEditing", res);
		return res;
	};

	if (!cart) return null;

	return (
		<div className="cart">
			<div className="cart-box expand">
				<div className="cart-header">
					<div
						onClick={() =>
							setPopup({
								title: "Coperti",
								content: (
									<List
										mode={3}
										elements={generateCovers()}
										onClick={(el) => {
											setPopup(false);
											editCart2({ covers: el.id });
										}}
									/>
								),
							})
						}
					>
						{cart.delivery_type && (
							<div className="large inline">
								<div className="icon">
									{cart.delivery_type.id == "delivery" && <Delivery />}
									{cart.delivery_type.id == "takeaway" && <Takeaway />}
									{cart.delivery_type.id == "shop" && <Table />}
								</div>
								{cart.delivery_type.name}
							</div>
						)}
						{cart.table && (
							<>
								<div className="small">
									Sala: {rooms.find((r) => r.id == cart.room_id)?.name}
								</div>
								<div className="small">Tavolo: {cart.table.name}</div>
							</>
						)}
						{cart.customer && (
							<div className="small">
								Cliente: {cart.customer.name} {cart.customer.surname}
							</div>
						)}
						{cart.address && (
							<div className="small">
								Indirizzo: {cart.address.address} {cart.address.number},{" "}
								{cart.address.city} ({cart.address.doorphone})
							</div>
						)}
						{cart.delivery_date && (
							<div className="small">
								Data:{" "}
								{moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
									"DD/MM/YYYY HH:mm"
								)}
							</div>
						)}
						<div className="small">Coperti: {cart.covers}</div>
						{isEditing(cart) && (
							<>
								<div className="small">Ordine {cart.order_id}</div>
								<div className="small">
									Prepagato: {cart.payment_method?.prepaid == 1 ? "Si" : "No"}
								</div>
							</>
						)}
					</div>
					<Flows />
				</div>
				<div className="scroll">
					{cart.products.map(
						(prod, i) =>
							prod.flow == activeFlow && (
								<div key={i} className="cart-product-line">
									<div className="cart-product-line-qty">{prod.qty}</div>
									<button
										className="cart-product-line-name"
										onClick={() => setEditingProduct({ i: i, data: prod })}
									>
										<div className="cart-product-name">{prod.name}</div>
										<div className="small">
											{prod.variants_categories.map(
												(vc, n) =>
													vc.selected > 0 && (
														<div key={n}>
															<div className="bold">{vc.name}</div>
															{vc.variants.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
															{vc.variants2.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
															{vc.products.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
														</div>
													)
											)}
											{prod.ingredients.map(
												(v, x) =>
													v.removed && <div key={x}>{"No " + v.name}</div>
											)}
										</div>
										{prod.notes && (
											<div
												className="small"
												style={{ whiteSpace: "pre-wrap" }}
											>
												<b>Note prodotto</b>
												<br />
												{prod.notes}
											</div>
										)}
									</button>
									<div key={i} className="cart-product-line-right">
										<div className="cart-product-line-price">
											{functions.formatter.format(prod.price * prod.qty)}
										</div>
										<div className="cart-product-line-buttons">
											<button onClick={() => subQty(i)}>-</button>
											<button onClick={() => addQty(i)}>+</button>
										</div>
									</div>
								</div>
							)
					)}
				</div>
			</div>
			<div className="cart-box order-notes">
				<div className="cart-command-buttons">
					<button
						className="button "
						onClick={() =>
							setPopup({
								title: "Note ordine",
								content: (
									<FormGroup
										type="textarea"
										value={cart.notes}
										onChange={(e) => {
											editCart2({ notes: e.target.value });
										}}
									/>
								),
							})
						}
					>
						<PlusCircle />
						Note Ordine
					</button>
					{isEditing() && (
						<>
							{edited ? (
								<button className="square disabled">
									<SplitIcon />
								</button>
							) : (
								<button
									className="square"
									onClick={() => setSplitPopup({ mode: "cart", order: cart })}
								>
									<SplitIcon />
								</button>
							)}
						</>
					)}
				</div>
				{cart.notes && (
					<div className="small" style={{ whiteSpace: "pre-wrap" }}>
						<b>Note Ordine</b>
						<br />
						{cart.notes}
					</div>
				)}
			</div>
			<div className="cart-box">
				{cart.discounts.map((d, i) => (
					<div key={i} className="subtotal">
						<div>
							<button className="button-2" onClick={() => removeDiscount(i)}>
								<TrashIcon />
							</button>
							<div>
								{d.name +
									(d.value_type == "perc" || d.value_type == "percent"
										? " " + d.value + "%"
										: "")}
							</div>
						</div>
						<div>
							{d.price > 0 && "-"}
							{functions.formatter.format(d.price > 0 ? d.price : -d.price)}
						</div>
					</div>
				))}
				{deliveryPrice > 0 && (
					<div className="subtotal">
						<div>Consegna</div>
						<div>{functions.formatter.format(deliveryPrice)}</div>
					</div>
				)}
				{cart.credit_total > 0 && (
					<div className="subtotal">
						<div>Credito cliente</div>
						<div>-{functions.formatter.format(cart.credit_total)}</div>
					</div>
				)}
				{round != 0 && (
					<div className="subtotal">
						<div>Arrotondamento:</div>
						<div>{functions.formatter.format(round)}</div>
					</div>
				)}
				<div className="total">
					<div>Totale:</div>
					<div className="total-price">{functions.formatter.format(total)}</div>
				</div>
			</div>
			<div className="cart-box">
				{error && <div className="error">{error}</div>}
				<div className="buttons-group">
					<button
						className="button button-3 square"
						onClick={() =>
							setConfirm({
								title: "Sei sicuro?",
								message:
									"Procedendo eliminerai tutti i prodotti presenti nel carrello",
								onConfirm: () => editCart2({ products: [] }),
							})
						}
					>
						<TrashIcon />
					</button>
					<button
						className="button button-3 square"
						onClick={() => setDiscountsPopup(true)}
					>
						<PrecentageIcon />
					</button>
					{valid ? (
						isEditing() ? (
							<>
								{edited ? (
									<button
										className="button button-2"
										onClick={() => updateOrderFromCart()}
									>
										Invia
									</button>
								) : (
									<button
										className="button button-2"
										onClick={() => updateOrderFromCart(false, false, true)}
									>
										Invia
									</button>
								)}
							</>
						) : (
							<button
								className="button button-2"
								onClick={() => checkout(config.keep_open_after_checkout == 1)}
							>
								Invia
							</button>
						)
					) : (
						<button className="button button-2 disabled">Invia</button>
					)}
					{valid ? (
						<button
							className="button button-2"
							onClick={() => {
								if (cart.customer) setInvoicePopup(true);
								else {
									setConfirm({
										title: "Cliente mancante",
										message:
											"Devi associare un cliente all'ordine per richiedere fattura",
									});
								}
							}}
						>
							Fattura
						</button>
					) : (
						<button className="button button-2 disabled">Fattura</button>
					)}
					{valid ? (
						isEditing() ? (
							<button
								className="button button-2"
								onClick={() => {
									total > 0
										? setPopup({
												title: "Modalità di pagamento",
												content: (
													<List
														elements={shop.payment_methods}
														onClick={(choiche) => {
															setPopup(false);
															if (choiche && choiche.id == 14)
																updateOrderFromCart(
																	choiche,
																	false,
																	false,
																	true
																);
															else updateOrderFromCart(choiche, true);
														}}
													/>
												),
										  })
										: updateOrderFromCart(false, true);
								}}
							>
								<PaymentName order={cart} />
							</button>
						) : (
							<button
								className="button button-2"
								onClick={() =>
									setPopup({
										title: "Modalità di pagamento",
										content: (
											<List
												elements={shop.payment_methods}
												onClick={(choiche) => {
													setPopup(false);
													if (choiche && choiche.id == 14)
														checkout(false, choiche, false, true);
													else checkout(false, choiche, true, false);
												}}
											/>
										),
									})
								}
							>
								{apiSettings.mandant.fiscal_printers == 1
									? "Scontrino"
									: "Completa"}
							</button>
						)
					) : (
						<button className="button button-2 disabled">
							{apiSettings.mandant.fiscal_printers == 1 ? "Scontrino" : "Completa"}
						</button>
					)}
				</div>
				{isEditing() && (
					<div className="buttons-group">
						{apiSettings.mandant.printers == 1 &&
							apiSettings.mandant.fiscal_printers == 0 &&
							isEditing() && (
								<button
									className="button button-1 square"
									onClick={() =>
										setConfirm({
											title: "Stampare copia comanda?",
											message: "",
											onConfirm: () =>
												updateOrderFromCart(false, false, true),
										})
									}
								>
									<PrinterIcon />
								</button>
							)}
						<button
							className="button button-1"
							onClick={() =>
								setConfirm({
									title: "Sei sicuro?",
									message: "Procedendo annullerai l'ordine corrente",
									onConfirm: () => {
										init_cart();
										cancelOrder();
									},
								})
							}
						>
							Archivia Ordine
						</button>
						<button
							className="button button-1"
							onClick={() =>
								setConfirm({
									title: "Sei sicuro?",
									message: "",
									onConfirm: () => {
										init_cart();
									},
								})
							}
						>
							Nuovo Ordine
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

function PaymentName({ order }) {
	if (order.payment_method?.prepaid == 1)
		return (
			<div>
				Scontrino
				{order.payment_method && (
					<div className="extra-small">{order.payment_method.name}</div>
				)}
			</div>
		);
	return "Scontrino";
}

export default Cart;
