import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import List from "../List";
import InvoiceFormPopup from "./InvoiceFormPopup";

function InvoicePopup() {
	const {
		cart,
		setInvoiceFormPopup,
		setLoading,
		setConfirm,
		setInvoicePopup,
		init_cart,
		updateOrder,
		invoiceFormPopup,
		checkout,
		config,
	} = useContext(AppContext);
	const [searchTerm, setSearchTerm] = useState("");
	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		refreshAddress();
	}, []);

	const refreshAddress = async () => {
		setLoading(true);
		const res = await api.postProtected("/addresses_billing/list/", {
			src: [{ name: "customer_id", value: cart.customer.id, compare: "equal" }],
		});
		setLoading(false);
		if (res.success == 1) {
			setAddresses(res.rows);
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const isEditing = () => {
		//console.log("isEditing", cart);
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		//console.log("isEditing", res);
		return res;
	};

	return (
		<>
			<PopUp
				size=""
				options={{
					onClose: () => setInvoicePopup(false),
					title: "Fattura",
					headerButtons: (
						<button onClick={() => setInvoiceFormPopup(true)}>Crea Nuovo</button>
					),
				}}
			>
				<div className="inner-content">
					<List
						elements={addresses}
						customElement={(c, i) => (
							<div key={i} className="list-element group">
								<div>
									{c.company}
									<div className="small">{"P.iva " + c.vat}</div>
									<div className="small">{"Sdi " + c.sdi}</div>
									<div className="small">{"C.F. " + c.taxcode}</div>
									<div className="small">{"Pec " + c.pec}</div>
									<div className="small">{c.address + " " + c.number}</div>
									<div className="small">
										{c.zipcode + " " + c.city + " (" + c.province + ")"}
									</div>
								</div>
								<div className="buttons">
									<button
										onClick={() => {
											setInvoiceFormPopup(c);
										}}
									>
										Modifica
									</button>
									<button
										onClick={() => {
											if (isEditing())
												setConfirm({
													title: "Sei sicuro?",
													message:
														"Procedendo l'ordine non sarà più modificabile",
													onConfirm: async () => {
														setInvoicePopup(false);
														updateOrder(
															{
																id: cart.order_id,
																$loki: cart["$loki"],
															},
															{
																invoice: 1,
																billing_address: c,
															}
														);
														init_cart();
													},
												});
											else {
												checkout(false, 4, false, false, {
													invoice: 1,
													billing_address: c,
													billing_address_id: c.id,
												});
												setInvoicePopup(false);
											}
										}}
									>
										Emetti fattura
									</button>
								</div>
							</div>
						)}
					/>
				</div>
			</PopUp>
			{invoiceFormPopup && (
				<InvoiceFormPopup address={invoiceFormPopup} refreshAddress={refreshAddress} />
			)}
		</>
	);
}

export default InvoicePopup;
