import { useContext, useState } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import CustomersPopUp from "./CustomersPopUp";
import FormGroup from "./form/FormGroup";
import areaCodes from "../data/areaCodes.json";

function CustomerForm({ customer }) {
	const { setPopup, editCart2, setConfirm } = useContext(AppContext);
	const [name, setName] = useState(customer ? customer.name : "");
	const [surname, setSurname] = useState(customer ? customer.surname : "");
	const [email, setEmail] = useState(customer ? customer.email : "");
	const [areaCode, setAreaCode] = useState(customer ? customer.area_code : "");
	const [mobile, setMobile] = useState(customer ? customer.mobile : "");
	const [birthDate, setBirthDate] = useState(customer ? customer.birth_date : "");

	const saveCustomer = async () => {
		if (customer) {
			const res = await api.postProtected("/customers/edit/" + customer.id + "/", {
				name: name,
				surname: surname,
				email: email,
				mobile: mobile,
				area_code: areaCode,
				birth_date: birthDate,
			});
			editCart2({ customer: res.data });
			setPopup(false);
		} else {
			const res = await api.post("/customers/add/", {
				name: name,
				surname: surname,
				email: email,
				mobile: mobile,
				area_code: areaCode,
				birth_date: birthDate,
			});
			if (res.success == 0)
				setConfirm({
					title: "Errore",
					message: res.error,
				});
			else {
				editCart2({ customer: res.data });
				setPopup(false);
			}
		}
	};

	return (
		<div className="form">
			<FormGroup label="Nome" value={name} onChange={(e) => setName(e.target.value)} />
			<FormGroup
				label="Cognome"
				value={surname}
				onChange={(e) => setSurname(e.target.value)}
			/>
			<FormGroup
				type="email"
				label="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<FormGroup
				type="container"
				childs={[
					<FormGroup
						label="Prefisso"
						value={areaCode}
						onChange={(e) => setAreaCode(e.target.value)}
						type="select"
						options={areaCodes}
						optionValue="dial_code"
						optionLabel={["dial_code", "code"]}
					/>,
					<FormGroup
						label="Cellulare"
						value={mobile}
						onChange={(e) => setMobile(e.target.value)}
					/>,
				]}
			/>
			<FormGroup
				label="Data di nascita"
				type="date"
				value={birthDate}
				onChange={(e) => setBirthDate(e.target.value)}
			/>
			<FormGroup type="spacer" />

			<button
				onClick={() =>
					setPopup({
						title: "Cliente",
						content: <CustomersPopUp />,
					})
				}
			>
				Annulla
			</button>
			<button className="success" onClick={saveCustomer}>
				Salva
			</button>
		</div>
	);
}

export default CustomerForm;
