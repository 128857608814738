import { useContext, useState } from "react";
import { AppContext } from "../App";
import FormGroup from "./form/FormGroup";

function Settings() {
	const { apiSettings, setSettingsVisible, config, setConfig, fiscalPrinters } =
		useContext(AppContext);
	const [page, setPage] = useState("main");

	return (
		<div className="settings">
			<div className="settings-container">
				<div className="settings-header">
					<div>Impostazioni</div>
					<button onClick={() => setSettingsVisible(false)}>&times;</button>
				</div>
				<div className="settings-columns">
					<div className="settings-menu">
						<button onClick={() => setPage("main")}>Generali</button>
						<button onClick={() => setPage("visual")}>Visualizzazione</button>
						<button onClick={() => setPage("local_server")}>Server Locale</button>
						{apiSettings?.mandant?.fiscal_printers == 1 && (
							<button onClick={() => setPage("fiscal_printer")}>
								Stampante Fiscale
							</button>
						)}
					</div>
					{page == "main" && (
						<div className="settings-content">
							<h2>Generali</h2>
							<FormGroup
								label="Conferma ordine al salvataggio"
								name="confirm_order"
								type="select"
								value={config.confirm_order}
								onChange={(e) =>
									setConfig({ ...config, confirm_order: e.target.value })
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
							<FormGroup
								label="Mantieni l'ordine aperto dopo l'invio"
								name="keep_open_after_checkout"
								type="select"
								value={config.keep_open_after_checkout}
								onChange={(e) =>
									setConfig({
										...config,
										keep_open_after_checkout: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
						</div>
					)}
					{page == "visual" && (
						<div className="settings-content">
							<h2>Visualizzazione</h2>
							<FormGroup
								label="Altezza pulsante prodotto"
								name="product_button_height"
								type="select"
								value={config.product_button_height}
								onChange={(e) =>
									setConfig({ ...config, product_button_height: e.target.value })
								}
								options={[
									{ name: "Fissa", id: "fixed" },
									{ name: "Auto", id: "auto" },
								]}
							/>
						</div>
					)}
					{page == "local_server" && (
						<div className="settings-content">
							<h2>Server Locale</h2>
							<FormGroup
								label="Modalità"
								name="mode"
								type="select"
								value={config.mode}
								onChange={(e) => setConfig({ ...config, mode: e.target.value })}
								options={[
									{ name: "Cloud", id: "remote" },
									{ name: "Server locale", id: "local" },
								]}
							/>
						</div>
					)}
					{page == "fiscal_printer" && (
						<div className="settings-content">
							<h2>Stampante Fiscale</h2>
							<FormGroup
								label="Stampante"
								name="fiscal_printer_id"
								type="select"
								value={config.fiscal_printer_id}
								onChange={(e) =>
									setConfig({ ...config, fiscal_printer_id: e.target.value })
								}
								options={[{ id: null, name: "" }, ...fiscalPrinters]}
							/>
							<FormGroup
								label="Attiva controllo chiusura giornaliera"
								name="check_fiscal_closure"
								type="select"
								value={config.check_fiscal_closure}
								onChange={(e) =>
									setConfig({
										...config,
										check_fiscal_closure: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Settings;
