import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import List from "./List";
import api from "./api/api";
import CustomerForm from "./CustomerForm";

function CustomersPopUp() {
	const { setPopup, editCart2, setConfirm, cart, shop } = useContext(AppContext);
	const [customers, setCustomers] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchAddressTerm, setSearchAddressTerm] = useState("");

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			console.log(searchTerm);
			if (!searchTerm) {
				setCustomers([]);
				return false;
			}
			const res = await api.postProtected("/customers/list/", {
				src: [
					{ name: "global", value: searchTerm, compare: "id,name,surname,email,mobile" },
				],
			});
			setCustomers(res.rows);
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			console.log(searchAddressTerm);
			if (!searchAddressTerm) {
				setAddresses([]);
				return false;
			}
			const res = await api.postProtected("/pos2/src_addresses_delivery/?debug=1", {
				shop_id: shop.id,
				term: searchAddressTerm,
			});
			setAddresses(res.rows);
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchAddressTerm]);

	return (
		<>
			<h3>Ricerca cliente per nome o per indirizzo oppure creane uno nuovo</h3>
			<div className="input-group">
				<input
					type="text"
					placeholder="Inserisci / Ricerca cliente"
					autoFocus
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				{cart.delivery_type.id == "delivery" && (
					<input
						type="text"
						placeholder="Ricerca indirizzo"
						onChange={(e) => setSearchAddressTerm(e.target.value)}
					/>
				)}
				{cart.delivery_type.id != "delivery" && (
					<button
						className="save-temp-client"
						onClick={() => {
							if (searchTerm) {
								editCart2({
									customer: null,
									name: searchTerm,
									delivery_date: false,
								});
								setPopup(false);
							} else
								setConfirm({
									title: "Errore",
									message: "Inserire un valore nel campo di ricerca",
								});
						}}
					>
						Salva Cliente temporaneo
					</button>
				)}
				<button onClick={() => setPopup({ title: "Cliente", content: <CustomerForm /> })}>
					Crea Nuovo
				</button>
			</div>
			<List
				elements={customers}
				customElement={(c, i) => (
					<div key={i} className="list-element group">
						<div className="client-info">
							<div className="client-name">{c.surname + " " + c.name}</div>
							<div className="small">{c.area_code + c.mobile + " - " + c.email}</div>
						</div>
						<div className="buttons">
							<button
								onClick={() =>
									setPopup({
										title: "Cliente",
										content: <CustomerForm customer={c} />,
									})
								}
							>
								Modifica
							</button>
							<button
								onClick={(el) => {
									if (cart.delivery_type.id == "shop") {
										editCart2({
											customer: c,
											address: false,
										});
									} else {
										editCart2({
											customer: c,
											address: false,
											delivery_date: false,
										});
									}
									setPopup(false);
								}}
							>
								Seleziona
							</button>
						</div>
					</div>
				)}
			/>
			<List
				elements={addresses}
				customElement={(c, i) => (
					<div key={i} className="list-element group">
						<div>
							{c.address + " " + c.number}
							<div className="small">
								{c.zipcode + " " + c.city + " (" + c.province + ")"}
							</div>
							<div className="small">
								{c.customer.surname +
									" " +
									c.customer.name +
									" - " +
									c.customer.email +
									" - " +
									c.customer.mobile}
							</div>
						</div>
						<div className="buttons">
							{c.covered == 1 ? (
								<button
									onClick={() => {
										setPopup(false);
										if (cart.delivery_type.id == "shop") {
											editCart2({
												customer: c.customer,
												address: c,
											});
										} else {
											editCart2({
												customer: c.customer,
												address: c,
												delivery_date: false,
											});
										}
									}}
								>
									Seleziona
								</button>
							) : (
								<button>Non coperto</button>
							)}
						</div>
					</div>
				)}
			/>
		</>
	);
}

export default CustomersPopUp;
